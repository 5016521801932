function formatPrice(_number) {
  // format price: 2000000 -> 2,000,000; 1120.30 -> 1,120.30
  let number = Number(_number);
  if (typeof number !== "number" || isNaN(number)) {
    return "--";
  }
  let [integer, decimal] = number.toString().split(".");
  // reverse integer string to match from ones digits
  let result = integer
    .split("")
    .reverse()
    .join("")
    .replace(/(\d{3})(?=\d)/g, "$1" + ",")
    .split("")
    .reverse()
    .join("");
  return `${result}${decimal ? `.${decimal}` : ""}`;
}

export { formatPrice };
